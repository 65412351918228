export const socialLinks = {
  sg: {
    instagram: 'https://www.instagram.com/zenyum.sg/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyumsg',
    twitter: 'https://twitter.com/zenyum_sg',
    youtube: 'https://www.youtube.com/channel/UCnXMGTvteACmaRlsD6kDz5A',
    tiktok: 'https://www.tiktok.com/@zenyum.official',
    weChat: 'https://www.zenyum.com/wp-content/uploads/2020/05/qrcode_for_gh_171888171a80_258.jpg'
  },
  my: {
    instagram: 'https://www.instagram.com/zenyum.my/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyummy',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCLluulgMm3B1oCF-jGiM-TA',
    tiktok: 'https://www.tiktok.com/@zenyum.malaysia'
  },
  th: {
    instagram: 'https://www.instagram.com/zenyum.th/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyumth',
    twitter: 'https://twitter.com/zenyumth',
    youtube: 'https://www.youtube.com/channel/UCwG-baStanvle8G6AHGEf6A',
    tiktok: 'https://www.tiktok.com/@zenyumthailand',
    line: 'https://lin.ee/kBZn0jn'
  },
  hk: {
    instagram: 'https://www.instagram.com/zenyum.hk/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyumhk',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UC0PUqFgMo6iTL2UQP8Wua-w?',
    tiktok: ''
  },
  tw: {
    instagram: 'https://www.instagram.com/zenyum.tw/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyumtw',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UC6Y93RzTSNsYhbW4aoXDmyg',
    tiktok: 'https://www.tiktok.com/@zenyum.official',
    line: 'https://lin.ee/dDDTqkd'
  },
  vn: {
    instagram: 'https://www.instagram.com/zenyum.vn/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyumvn',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCRHHbV4SurrsudAQmOExJ0w',
    tiktok: 'https://www.tiktok.com/@zenyumvn'
  },
  id: {
    instagram: 'https://www.instagram.com/zenyum.id/',
    gplus: '',
    facebook: 'https://www.facebook.com/zenyum.id',
    tiktok: 'https://www.tiktok.com/@zenyum.official',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCwVBZVmGRIY7z7eq3aUMN7g'
  },
  jp: {
    instagram: 'https://www.instagram.com/zenyum.jp/',
    gplus: '',
    facebook: 'https://www.facebook.com/Zenyum-Japan-109865804740499',
    tiktok: 'https://www.tiktok.com/@zenyumjapan',
    twitter: '',
    youtube: ''
  }
};

export const externalLinks = {
  sg: {
    sonic: 'https://smilecosmetics.zenyum.com/sg-en/product/zenyum-sonic-subscription/',
    bright: 'https://smilecosmetics.zenyum.com/sg-en/product/zenyum-white/',
    blog: 'https://www.zenyum.com/sg-en/smile-academy/'
  },
  my: {
    sonic: 'https://smilecosmetics.zenyum.com/my-en/product/zenyum-sonic-subscription/',
    bright: 'https://smilecosmetics.zenyum.com/my-en/coming-soon-whitening/',
    blog: 'https://www.zenyum.com/my-en/smile-academy/'
  },
  hk: {
    en: {
      sonic: 'https://smilecosmetics.zenyum.com/hk-en/product/zenyum-sonic-subscription/',
      bright: 'https://smilecosmetics.zenyum.com/hk-en/product/zenyum-white/',
      blog: 'https://www.zenyum.com/hk-en/blog/'
    },
    zh: {
      sonic: 'https://smilecosmetics.zenyum.com/hk-zh/product/zenyum-sonic-subscription/',
      bright: 'https://smilecosmetics.zenyum.com/hk-zh/product/zenyum-white/',
      blog: 'https://www.zenyum.com/hk-zh/blog/'
    },
    sonic: 'https://smilecosmetics.zenyum.com/hk-zh/product/zenyum-sonic-subscription/',
    bright: 'https://smilecosmetics.zenyum.com/hk-zh/product/zenyum-white/',
    blog: 'https://www.zenyum.com/hk-en/smile-academy/'
  },
  vn: {
    sonic: 'https://smilecosmetics.zenyum.com/vn-vi/coming-soon/',
    bright: 'https://smilecosmetics.zenyum.com/vn-vi/coming-soon/',
    blog: 'https://www.zenyum.com/vn-vi/blog/'
  },
  th: {
    sonic: 'https://www.lazada.co.th/shop/zenyumth',
    bright: 'https://smilecosmetics.zenyum.com/th-th/coming-soon/',
    blog: 'https://www.zenyum.com/th-th/smile-academy/'
  },
  id: {
    sonic: 'https://shopee.co.id/Zenyum-Sikat-Gigi-Elektrik-Electric-Toothbrush-Jastip-Import-Handcarry-Singapore-i.142312633.15449879476?sp_atk=412ab881-57f6-4d5f-b4f2-92692b5d8a5c&xptdk=412ab881-57f6-4d5f-b4f2-92692b5d8a5c',
    bright: 'https://smilecosmetics.zenyum.com/id-id/sonic-troveskin-id/',
    blog: 'https://www.zenyum.com/id-id/smile-academy/'
  },
  tw: {
    sonic: 'https://smilecosmetics.zenyum.com/tw-zh/coming-soon/',
    bright: 'https://smilecosmetics.zenyum.com/tw-zh/coming-soon/',
    blog: 'https://www.zenyum.com/tw-zh/blog/'
  },
  jp: {
    sonic: 'https://smilecosmetics.zenyum.com/tw-zh/coming-soon/',
    bright: 'https://smilecosmetics.zenyum.com/tw-zh/coming-soon/',
    blog: 'https://www.zenyum.com/jp-ja/smile-academy/'
  }

};

export const reviewLinksJp = {
  google: 'https://www.google.com/maps/place/Zenyum+Japan+%7C+%E3%82%BC%E3%83%8B%E3%83%A5%E3%83%A0+%E3%82%B8%E3%83%A3%E3%83%91%E3%83%B3/@35.6690322,139.756497,17z/data=!3m1!5s0x60188bec1318fd89:0x6b73f1007adb973b!4m8!3m7!1s0x6018899299d55f8b:0xebb1edf06fa708ad!8m2!3d35.6690322!4d139.756497!9m1!1b1!16s%2Fg%2F11rv625yrc'
};

// markets in which Zenyum sonic is available through our platform.
export const sonicMarkets = ['sg', 'my', 'hk', 'vn', 'tw'];

// markets in which Zenyum sonic old story page is available through our platform.
export const sonicStoryMarkets = [];

// markets in which Zenyum bright is available through our platform.
export const brightMarkets = [];

// markets in which Zenyum Fresh is available through our platform.
export const freshMarkets = ['sg', 'hk', 'my', 'vn', 'tw'];

// markets in which Zenyum Fresh Breathshield is available through our platform.
export const breathshieldMarkets = ['sg', 'hk', 'my'];

// markets in which Floss is available through our platform.
export const flossMarkets = ['sg', 'my', 'hk', 'tw'];

// markets in which Toothpaste is available through our platform.
export const toothpasteMarkets = ['sg', 'my', 'hk', 'vn', 'tw'];

// markets in which Fresh Bundles is available through our platform.
export const freshBundleMarkets = ['sg', 'my', 'hk'];

// markets in which Waterflosser is available through our platform.
export const waterflosserMarkets = ['sg', 'my', 'hk', 'vn', 'tw'];

// markets in which WaterflosserTips is available through our platform.
export const waterflosserTipsMarkets = ['hk'];

// markets in which sonicGo is available through our platform.
export const sonicGoMarkets = ['sg', 'vn', 'my', 'hk', 'tw'];

// markets in which sonic brush heads are available through our platform
export const sonicBrushHeadMarkets = ['sg', 'my', 'hk', 'tw', 'vn'];

// markets in which sonic brush heads are available through our platform
export const sonicGentleBrushHeadMarkets = ['sg', 'my', 'hk', 'tw'];

// markets in which sonicgo brush heads are available through our platform
export const sonicGoBrushHeadMarkets = ['sg', 'my', 'hk', 'tw', 'vn'];

// markets with common landing and aligner page
export const alignerLandingPageCountries = ['jp'];

// markets in which whitening is available through our platform.
export const whiteningMarkets = ['sg', 'hk', 'my', 'vn'];

// COD payment enabled markets
export const codMarkets = ['vn', 'tw'];

// markets in which Referral program is enabled.
export const referralMarkets = [];

// markets in which Referrals is enabled.

export const referralEnabledMarkets = ['sg', 'hk', 'my', 'vn', 'th', 'tw', 'jp'];

// markets in which Zenyum sonic White Toothbrush OOS
export const sonicWhiteOOSMarkets = [];

// markets in which Zenyum sonic Black Toothbrush OOS
export const sonicBlackOOSMarkets = [];

// markets in which Zenyum sonic Toothbrush OOS
export const sonicOOSMarkets = [];

// markets in which Zenyum sonic pink starterKit is disabled.
export const pinkDisabledMarkets = [];

// markets in which Zenyum sonic pastel is available.
export const pastelMarkets = ['sg', 'my', 'hk', 'vn', 'th', 'tw'];

// markets in which UV box is available.
export const uvboxMarkets = [];

// markets in which UV box is available.
export const travelcaseMarkets = ['sg', 'my', 'hk'];

// markets in which Festive bundles is available.
export const festiveBundleMarkets = [];

// markets in which Cart function is disabled.
export const cartDisabledMarkets = ['id', 'jp', 'th'];

// markets in which Cart function is enabled.
export const cartEnabledMarkets = ['sg', 'vn', 'my', 'hk', 'tw'];

// markets in which Warranty Activation is enabled.
export const warrantyEnabledMarkets = ['sg', 'my'];

// markets in which Faq is disabled.
export const faqDisabledMarkets = ['th'];

// markets in which Faq is enabled.
export const faqEnabledMarkets = ['sg', 'vn', 'my', 'hk', 'tw', 'jp', 'id'];

// markets in which Zenyum sonic pink Brushhead is disabled.
export const pinkHeadDisabledMarkets = [];

// markets in which Zenyum bright dentist consultation option is disabled.
export const disableDentistConsult = ['hk'];

// markets in which Zenplus markets are available.
export const zenplusMarkets = ['sg', 'vn', 'my', 'hk', 'tw', 'th', 'jp'];

// Whatsapp consent markets
export const whatsAppMarkets = ['sg', 'my', 'hk'];

//  markets in which new review plugins are enabled
export const reviewsIoMarkets = ['sg', 'my', 'vn', 'tw', 'hk'];

//  markets in which festive gifts are enabled
export const festiveMarkets = ['sg', 'my', 'hk'];

// markets in which ZenyumSonic™ Go Pokémon Collection are enabled
export const pokemonCollectionEnabledMarkets = process.env.NEXT_PUBLIC_POKEMON_GO_ENABLED_MARKETS || ''.split(',');

// markets in which ZenyumSonic™ Go Pokémon Collection are enabled
export const sonicGoSetEnabledMarkets = ['sg', 'vn', 'my', 'hk'];

// markets which shopify redirection enabled
export const shopifyEnabledMarkets = process.env.NEXT_PUBLIC_SHOPIFY_ENABLED_MARKETS || ''.split(',');

export const obt3EnabledMarkets = process.env.NEXT_PUBLIC_OBT3_ENABLED_MARKETS.split(',');

// markets which shopify redirection for homepage enabled

export const homePageShopifyRedirectionEnabledMarkets = ['sg-en', 'hk-zh', 'jp-ja'];

export const shopifyRedirectablePageUrls = ['faqs', 'about', 'invisible-braces'];

// Rounding UP of total amount in COD orders for taiwan
export const isRoundingEnabledTWCOD = true;

export const notRestrictedPages = {
  jp: ['commercial_law', 'privacy', 'tac', 'referral-mechanism']
};

// Order for sonic colors
export const colorOrder = ['DEFAULT',
  'BLACK',
  'WHITE',
  'BLACK_WHITE',
  'PINK',
  'BLACK_PINK',
  'WHITE_BLACK',
  'WHITE_PINK',
  'BLUE',
  'BLACK_BLUE',
  'WHITE_BLUE',
  'PINK_BLUE',
  'GREEN',
  'BLACK_GREEN',
  'WHITE_GREEN',
  'PINK_GREEN',
  'BLUE_GREEN'
];

// markets in which Zenplus markets are available.
export const chatBotMarkets = [];

export const countriesWithColoredSurveyScales = ['sg', 'hk'];

export const embedCodes = {
  sg: {
    aligner: '1eaeff0575260a47103cf9bbbf6dbea5f763f374',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: 'd5eec5c260798d4fa82e140ec46bf33112f50415',
    sonic_instagram: 'e28ce07e59263f2f47ae8cebdcb20911acdcf883',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  my: {
    aligner: 'c896b2e2b760b6cb5a7138253a44e41ef4d3778b',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '7faaa395d4ab4953a6af8294a2a8e375c1c58385',
    sonic_instagram: '1772a1809321919a064579ec185ac0a364ae907c',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  hk: {
    aligner: '44d1c0870b9d3c8ee7c4e6377a2defec57553f57',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '22388b5c204f9d230d2a9593412fc3848de3b7fc',
    sonic_instagram: 'a218d05c9d1691a609eb383dedaed13b8d075454',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  vn: {
    aligner: '9f34cae3d99e2e3459bd992354770b38480ee97c',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '93956bd8f5087eb10286b19dc9dbb9e6d5d80031',
    sonic_instagram: '8d42e8fe4b6d707ce8577077273c2f4ffc4f577e',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  th: {
    aligner: '1eaeff0575260a47103cf9bbbf6dbea5f763f374',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '2cc302dfcbf6f1d8fc39d3bb11972ad9f30e0ab4',
    sonic_instagram: '2cc302dfcbf6f1d8fc39d3bb11972ad9f30e0ab4',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  id: {
    aligner: 'c809845a24fe841afd01e51298a953ed3040dc0a',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '9bf2c7f26caa5dbc0dd44864d677be88bbfccfcf',
    sonic_instagram: 'bebe0bd99d4dfe55dd5bc172ee053512ff61bf81',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  tw: {
    aligner: '1eaeff0575260a47103cf9bbbf6dbea5f763f374',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: '6b4cd089133c43f8652ed7dd749c19a471c62c75',
    sonic_instagram: '6b4cd089133c43f8652ed7dd749c19a471c62c75',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  },
  jp: {
    aligner: '0b8c3a91a2aa58acd1ac1fb128904d85a551cdc8',
    sonic: '772c8fedbd8c11b6892e235bc3a49a0826165155',
    bright: '8dae23f28f39c6c4e922d9ecab8fceae0064a999',
    instagram: 'a3fb851407b3e5f22cc6cf690479d0884161ebce',
    sonic_instagram: 'a3fb851407b3e5f22cc6cf690479d0884161ebce',
    bright_instagram: 'bac11d838f13871792a1135f04783160eb6c6ba4'
  }

};

export const productLinks = {
  ZENFRESH_DAY: 'zenfresh-toothpaste-day',
  ZENFRESH_NIGHT: 'zenfresh-toothpaste-night',
  ZENFRESH_DAYNIGHT: 'zenfresh-toothpaste-day',
  ZENFRESH_SHIELD: 'zenfresh-breathshield-details',
  ZENFRESH_FLOSS: 'zenfresh-floss-details',
  BUNDLE_SMILERITUAL: 'zenfresh-bundle-details',
  BUNDLE_ZENFRESH: 'zenfresh-bundle-details',
  SONICBRUSHHEAD_3P: 'zenyumsonic-brush-head',
  SONICBRUSH_COMBO3P: 'zenyumsonic-details',
  SONICBRUSH_SINGLE: 'zenyumsonic-details',
  ZENYUM_ALIGNERS: 'invisible-braces',
  SONICBRUSH_COMBO3PBOXCANDLE: 'zenyumsonic-details',
  SONICBRUSH_COMBO3PPOUCH: 'zenyumsonic-details',
  SONICBRUSH_POUCH: 'zenyumsonic-details',
  ZENYUMSONIC_STARTERKIT: 'zenyumsonic-details',
  WATERFLOSSER_PRO: 'waterflosserpro-details',
  WATERFLOSSER_SONICBUNDLE: 'waterflosserpro-details',
  WATERFLOSSER_TIPBUNDLE: 'waterflosserpro-details',
  WATERFLOSSER_TIP: 'waterflosserpro-tips-details',
  SONICGO_BRUSHHEAD3P: 'zenyumsonicgo-brush-head',
  SONICGO_BRUSHHEAD3PBUNDLE: 'zenyumsonicgo-details',
  SONICGO_MINIPASTESET: 'zenyumsonicgo-details',
  SONICGO_SONICBUNDLE: 'zenyumsonicgo-details',
  SONICGO_STARTERKIT: 'zenyumsonicgo-details',
  SONICGO_TRAVELSET: 'zenyumsonicgo-details',
  SONICGO_FRESHSET: 'zenyumsonicgo-details',
  SONICGO_TRAVELREFILL: 'zenyumsonicgo-details',
  SONICGO_PASTESETNIGHT: 'zenyumsonicgo-details',
  SONICGO_PASTESETDAY: 'zenyumsonicgo-details',
  SONIC_DUO: 'zenyumsonic-details',
  ZENYUMBRIGHT_STARTERKIT: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_28STRIPS: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_14STRIPS: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_SMILERITUALKIT: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_RITUALSTARTERKIT: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_PLUS: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_EXTRAVALUEPACK: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_TRAVELSET: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_STRIPANDPASTE: 'zenyumbright-teeth-whitening-strips-details',
  ZENYUMBRIGHT_STRIPANDPASTEDUO: 'zenfresh-bundle-details',
  ZENYUMBRIGHT_BMUADVANCED: 'zenfresh-bundle-details',
  ZENYUMBRIGHT_RITUALTRAVELKIT: 'zenyumbright-teeth-whitening-strips-details',
  SONIC_14WHITENINGBUNDLE: 'zenyumsonic-details',
  SONICGO_BUNDLEDUO: 'zenyumsonicgo-details',
  SONIC_FESTIVE: 'zenyum-festive-gift',
  SONICGO_DUOFESTIVE: 'zenyum-festive-gift',
  WHITENING_DUOFESTIVE: 'zenyum-festive-gift',
  ZENTA_PRO: 'zenyumsonic-details',
  ZENTA_GO: 'zenyumsonicgo-details',
  ZENTA_DUO: 'zenyumsonic-details',
  HOLIDAY_PACKAGE: 'zenyum-festive-gift',
  WHITENING_ONTHEGO: 'zenyumbright-teeth-whitening-strips-details',
  WHITENING_ADVANCEDSET: 'zenyumbright-teeth-whitening-strips-details',
  FRESH_SET: 'zenfresh-toothpaste-day',
  SONIC_PASTESET: 'zenyumsonic-details',
  SONIC_FRESHSET: 'zenyumsonic-details',
  SONIC_DAILYCAREKIT: 'zenyumsonic-details',
  GENTLEBRUSHHEAD_2PIECES: 'zenyumsonic-gentle-brush-head',
  GENTLEBRUSHHEAD_4PIECES: 'zenyumsonic-gentle-brush-head',
  GENTLEBRUSHHEAD_STARTERKIT: 'zenyumsonic-gentle-brush-head',
  GENTLEBRUSHHEAD_CLEANRITUAL: 'zenyumsonic-gentle-brush-head',
  GENTLEBRUSHHEAD_REFILLVARIETYPACK: 'zenyumsonic-gentle-brush-head', // ZenyumSonic Refill Variety Pack
  GENTLEBRUSHHEAD_RITUALSTARTER: 'zenyumsonic-gentle-brush-head',
  GENTLEBRUSHHEAD_GENTLEKIT: 'zenyumsonic-gentle-brush-head'
};

export const pageCodeMap = {
  ALIGNERS: 'invisible-braces',
  WHITENING: 'zenyumbright-teeth-whitening-strips-details',
  TOOTHBRUSH: 'zenyumsonic-details',
  SONICGO_TOOTHBRUSH: 'zenyumsonicgo-details',
  BRUSHHEAD: 'zenyumsonic-brush-head',
  SONICGO_BRUSHHEAD: 'zenyumsonicgo-brush-head',
  NSR: 'zenfresh-bundle-details',
  TOOTHPASTE_DAY: 'zenfresh-toothpaste-day',
  TOOTHPASTE_NIGHT: 'zenfresh-toothpaste-night',
  TOOTHPASTE: 'zenfresh-toothpaste-details',
  SHIELD: 'zenfresh-breathshield-details',
  WATERFLOSSER: 'waterflosserpro-details',
  FLOSS: 'zenfresh-floss-details',
  FLOSSERTIP: 'waterflosserpro-tips-details',
  FESTIVE: 'zenyum-festive-gift',
  GENTLEBRUSHHEADS: 'zenyumsonic-gentle-brush-head'
};
export const analyticProductData = {
  WATERFLOSSER_SONICBUNDLE: { category: 'Floss', product: 'Waterflosser Sonic Bundle' },
  ZENYUM_ALIGNERS: { category: 'Invisible Braces', product: 'Invisible Braces' },
  ZENFRESH_SHIELD: { category: 'Toothpaste and Mouthwash', product: 'Breath Shield' },
  SONICGO_BRUSHHEAD3P: { category: 'Electric Toothbrush', product: 'SonicGo Brush Head 3-Pack' },
  SONICGO_BRUSHHEAD3PBUNDLE: { category: 'Electric Toothbrush', product: 'SonicGo Brush Head 3-Pack Bundle' },
  SONICGO_SONICBUNDLE: { category: 'Electric Toothbrush', product: 'SonicGo Bundle' },
  ZENFRESH_DAY: { category: 'Toothpaste and Mouthwash', product: 'Toothpaste Day' },
  ZENFRESH_NIGHT: { category: 'Toothpaste and Mouthwash', product: 'Toothpaste Night' },
  ZENFRESH_DAYNIGHT: { category: 'Toothpaste and Mouthwash', product: 'Toothpaste Day & Night' },
  ZENFRESH_FLOSS: { category: 'Floss', product: 'Zenfresh Floss' },
  BUNDLE_ZENFRESH: { category: 'Toothpaste and Mouthwash', product: 'Zenfresh Bundle' },
  WATERFLOSSER_PRO: { category: 'Toothpaste and Mouthwash', product: 'Waterflosser Pro' },
  WATERFLOSSER_TIPBUNDLE: { category: 'Floss', product: ' Waterflosser Tip Bundle' },
  WATERFLOSSER_TIP: { category: 'Floss', product: 'Waterflosser Tip' },
  SONICGO_TRAVELSET: { category: 'Electric Toothbrush', product: 'SonicGo Travelset' },
  BUNDLE_SMILERITUAL: { category: 'Toothpaste and Mouthwash', product: 'New Smile Ritual' },
  SONICBRUSHHEAD_3P: { category: 'Electric Toothbrush', product: 'Sonic Brush Head Pack 3-Pack' },
  SONICBRUSH_COMBO3P: { category: 'Electric Toothbrush', product: 'Sonic + Brush Head Pack' },
  SONICBRUSH_SINGLE: { category: 'Electric Toothbrush', product: 'Sonic Toothbrush' },
  SONICGO_MINIPASTESET: { category: 'Electric Toothbrush', product: 'SonicGo + Paste Mini Set' },
  SONICGO_TRAVELREFILL: { category: 'Electric Toothbrush', product: 'Sonic Go Travel+ Refill Set' },
  ZENYUMBRIGHT_28STRIPS: { category: 'Whitening', product: 'ZenyumBright Invisible Whitening Strips - 28 Strips' },
  ZENYUMBRIGHT_14STRIPS: { category: 'Whitening', product: 'ZenyumBright Invisible Whitening Strips - 14 Strips' },
  ZENYUMBRIGHT_SMILERITUALKIT: { category: 'Whitening', product: 'ZenyumBright Smile Ritual Kit' },
  ZENYUMBRIGHT_STARTERKIT: { category: 'Whitening', product: 'ZenyumBright Starter Kit' },
  ZENYUMBRIGHT_RITUALSTARTERKIT: { category: 'Whitening', product: 'ZenyumBright Smile Ritual Starter Kit' },
  ZENYUMBRIGHT_PLUS: { category: 'Whitening', product: 'ZenyumBright Plus' },
  ZENYUMBRIGHT_EXTRAVALUEPACK: { category: 'Whitening', product: 'ZenyumBright Extra Value Pack' },
  ZENYUMBRIGHT_TRAVELSET: { category: 'Whitening', product: 'ZenyumBright Travel Set' },
  ZENYUMBRIGHT_STRIPANDPASTE: { catgeory: 'Whitening', product: 'Bright Me Up Strip + Paste' },
  ZENYUMBRIGHT_STRIPANDPASTEDUO: { category: 'Whitening', product: 'Bright Me Up Strip Paste Duo' },
  ZENYUMBRIGHT_BMUADVANCED: { category: 'Whitening', product: 'Bright Me Up Advanced' },
  ZENYUMBRIGHT_RITUALTRAVELKIT: { category: 'Whitening', product: 'ZenyumBright Ritual Travel Kit' },
  SONICGO_BUNDLEDUO: { category: 'Electric Toothbrush', product: 'Sonic Go Bundle Duo' },
  SONIC_14WHITENINGBUNDLE: { category: 'Electric Toothbrush', product: 'Sonic and 14 Whitening Bundle' },
  SONIC_FESTIVE: { category: 'Festive Bundles', product: 'Sonic and 14 Whitening Bundle' },
  SONICGO_DUOFESTIVE: { category: 'Festive Bundles', product: 'Sonic and 14 Whitening Bundle' },
  WHITENING_DUOFESTIVE: { category: 'Festive Bundles', product: 'Sonic and 14 Whitening Bundle' },
  ZENTA_PRO: { category: 'Festive Bundles', product: 'Zenta Pro Sparkle of Joy' },
  ZENTA_GO: { category: 'Festive Bundles', product: 'Zenta Go Deer on the Go' },
  ZENTA_DUO: { category: 'Festive Bundles', product: 'Zenta Duo Melt My Heart' },
  HOLIDAY_PACKAGE: { category: 'Festive Bundles', product: 'Sonic and 14 Whitening Bundle' },
  ZENYUMSONIC_STARTERKIT: { category: 'Electric Toothbrush', product: 'ZenyumSonic Starter Kit' },
  SONICGO_STARTERKIT: { category: 'Electric Toothbrush', product: 'Sonic Go Starter Kit' },
  SONICGO_FRESHSET: { category: 'Toothpaste and Mouthwash', product: 'Sonic Go Fresh Set' },
  SONICGO_PASTESETNIGHT: { category: 'Toothpaste and Mouthwash', product: 'ZenyumFresh Toothpaste Night.' },
  SONICGO_PASTESETDAY: { category: 'Toothpaste and Mouthwash', product: 'ZenyumFresh Toothpaste Day!' },
  SONIC_DUO: { category: 'Electric Toothbrush', product: 'Sonic Duo' },
  WHITENING_ONTHEGO: { category: 'Whitening', product: 'On-the-Go Whitening' },
  WHITENING_ADVANCEDSET: { category: 'Whitening', product: 'Advanced Whitening Set' },
  FRESH_SET: { category: 'Toothpaste and Mouthwash', product: 'Fresh Set' },
  SONIC_PASTESET: { category: 'Sonic Fresh', product: 'Sonic Paste Set' },
  SONIC_FRESHSET: { category: 'Sonic Fresh', product: 'Sonic Fresh Set' },
  GENTLEBRUSHHEAD_2PIECES: { category: 'Gentle Brush Head', product: 'ZenyumSonic Refill Gentle Clean Brush Head 2P' },
  GENTLEBRUSHHEAD_4PIECES: { category: 'Gentle Brush Head', product: 'ZenyumSonic Refill Gentle Clean Brush Head 4P' },
  GENTLEBRUSHHEAD_STARTERKIT: { category: 'Gentle Brush Head', product: 'ZenyumSonic Gentle Starter Kit' },
  GENTLEBRUSHHEAD_CLEANRITUAL: { category: 'Gentle Brush Head', product: 'ZenyumSonic Gentle Clean Ritual' },
  GENTLEBRUSHHEAD_REFILLVARIETYPACK: { category: 'Gentle Brush Head', product: 'ZenyumSonic Refill Variety Pack' },
  GENTLEBRUSHHEAD_RITUALSTARTER: { category: 'Gentle Brush Head', product: 'ZenyumSonic Gentle Ritual Starter' },
  GENTLEBRUSHHEAD_GENTLEKIT: { category: 'Gentle Brush Head', product: 'ZenyumSonic Gentle Kit' }

};

export const calendlyLinks = {
  sg: 'https://calendly.com/zenyum-calls/book-a-call-singapore',
  my: 'https://calendly.com/zenyum-calls/book-a-call-malaysia',
  hk: 'https://calendly.com/zenyum-hk/book-a-call',
  id: 'https://calendly.com/zenyum-calls/book-a-call-indonesia',
  tw: 'https://calendly.com/zenyumtw/consultationbooking',
  th: 'https://calendly.com/zenyum-th/zenyum_thailand',
  vn: 'https://calendly.com/zenyum-vietnam/zenyum-vietnam',
  jp: 'https://calendly.com/zenyum-japan/book-a-call'
};

export const birthdayVideo = {
  sg: 'https://www.youtube.com/embed/nS8ck-xjuOM',
  my: 'https://www.youtube.com/embed/HjXv5mZoyCM',
  hk: {
    en: 'https://www.youtube.com/embed/OkRKjNBE_6c',
    zh: 'https://www.youtube.com/embed/d-pxcczV3qo'
  },
  id: 'https://www.youtube.com/embed/g3SWkXp4WiY',
  tw: 'https://www.youtube.com/embed/ii_8JNcztE8',
  th: 'https://www.youtube.com/embed/KdTfstzpvL0',
  vn: 'https://www.youtube.com/embed/iGgSmvWieNo',
  jp: ''
};
